import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { url } from "../../api";
import Tag from "../../components/tag";

export default function Card({ building }) {
  return (
    <Link
      to={`/predio?id=${building?.id}`}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        className="img-zoom mb-2"
        src={`${url}${building?.cartao?.url}`}
        alt={building?.cartao?.url}
        height="100"
      />
      <h2>{building?.nomePredio}</h2>
      <p>{building?.enderecoCheio?.cidade}</p>
      <Tag estado={building?.estado} />
    </Link>
  );
}
Card.propTypes = {
  building: PropTypes.any,
};
