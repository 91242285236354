import React, { useContext } from "react";
import PropTypes from "prop-types";
import Card from "./card.js";
import Link from "gatsby-link";
import adicionar from "../../images/adicionar.png";
import { AuthContext } from "../../context/GlobalContextProvider";
export default function PredioComponent({
  buildings,
  handleSort,
  dragItem,
  dragOverItem,
}) {
  const context = useContext(AuthContext);
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-12 self-center">
        {buildings?.map((building, index) =>
          context?.user && handleSort ? (
            <div
              draggable
              onDragStart={() => {
                dragItem.current = index;
              }}
              onDragEnter={() => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
              style={{ cursor: "move" }}
            >
              <Card building={building} key={building?.id} />
            </div>
          ) : (
            <Card building={building} key={building?.id} />
          )
        )}
        {context?.user && (
          <Link to="/empreendimentos/novoPredio">
            <img className="img-zoom" src={adicionar} />
          </Link>
        )}
      </div>
    </div>
  );
}
PredioComponent.propTypes = {
  buildings: PropTypes.any,
  handleSort: PropTypes.func,
  dragItem: PropTypes.any,
  dragOverItem: PropTypes.any,
};
