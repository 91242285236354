import React from "react";
import PropTypes from "prop-types";

export default function Tag({ estado }) {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#FF914D",
          borderRadius: "18px",
          opacity: 1,
          padding: "0 32px",
          height: "28px",
        }}
      >
        <div className="tag" style={{ paddingTop: "0.15rem", color: "black" }}>
          {estado === "Lancamento"
            ? "LANÇAMENTO"
            : estado === "Entregue"
            ? "ENTREGUE"
            : estado === "PreLancamento"
            ? "PRÉ-LANÇAMENTO"
            : estado === "EmConstrucao"
            ? "EM CONSTRUÇÃO"
            : "VENDIDO"}
        </div>
      </div>
    </div>
  );
}

Tag.propTypes = {
  estado: PropTypes.any,
};
